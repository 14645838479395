import React, { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import './Navbar.css';
import { BsBoxSeam } from "react-icons/bs";
import { BsJournalBookmark } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { FaRegHeart } from "react-icons/fa";
import { TbLogout2 } from "react-icons/tb";
import { TbMessageCircleQuestion } from "react-icons/tb";
import LoginComponent from '../../componentes/dialogs/login/login';
import SignupComponent from '../../componentes/dialogs/signup/signup';
import logo from '../../assets/images/logo-navbar.png';
import cartitem from '../../assets/images/user-cart-logo.png'
import profileIco from '../../assets/images/pfp.png'
import { IoMdArrowDropdown } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { getMe } from '../../shared/apis';
import CartComponent from '../home/cart/cart';
import offerImg from '../../assets/images/offer.png'
import Spinner from 'react-bootstrap/Spinner';

function Navbar({ itemsInCart, giftInCart, onCartError, cartForm, receiverAddress }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdownReg, setShowDropdownReg] = useState(false);
  const [showDropdownReg2, setShowDropdownReg2] = useState(false);
  const [loginActive, setLoginActive] = useState(true);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [signupMode, setSignupMode] = useState('email');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showCartPanel, setShowCartPanel] = useState(false);
  const [showCartPopup, setShowCartPopup] = useState(false);
  const [cartItems, setCartItems] = useState(itemsInCart);
  const [giftItems, setGiftItems] = useState(giftInCart);
  const paymentBy = localStorage.getItem('paymentBy');




  const toggleCartPopup = () => {
    setShowCartPopup(!showCartPopup);
  };

  const init = async () => {
    let user = await getMe();
    setUser(user.data.user);
    setLoading(false);

  };

  

  useEffect(() => {
    init();
    // setTimeout(() => {

    //   console.log('yes',paymentBy)
    // }, 6000);
    const userToken = localStorage.getItem('token');
    if (userToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);
  // useEffect(() => {
  //   const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  //   const storedGiftItems = JSON.parse(localStorage.getItem('giftItems')) || [];
  //   setCartItems(storedCartItems);
  //   setGiftItems(storedGiftItems);
  // }, [cartItems,giftItems]);
  // We will add a flag to detect changes
useEffect(() => {
  const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  const storedGiftItems = JSON.parse(localStorage.getItem('giftItems')) || [];
  setCartItems(storedCartItems);
  setGiftItems(storedGiftItems);
}, [localStorage.getItem('cartItems'), localStorage.getItem('giftItems')]);

  // useEffect(() => {
  //   const storedObjectString = localStorage.getItem('cartItems');
  //   const retrievedObject = JSON.parse(storedObjectString);
  //   if (retrievedObject) {
  //     setCartItems(retrievedObject);
  //   }
  // }, [cartItems]);

  // useEffect(() => {
  //   const storedObjectString2 = localStorage.getItem('giftItems');
  //   const retrievedObject2 = JSON.parse(storedObjectString2);
  //   if (retrievedObject2) {
  //     setGiftItems(retrievedObject2);
  //   }
  // }, [giftItems]);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleDropdownToggleReg = () => {
    setShowDropdownReg(!showDropdownReg);
  };

  const handleOptionClick = (option) => {
    setShowDropdownReg(false);
  };

  const handleDropdownToggle2 = () => {
    setShowDropdown2(!showDropdown2);
  };

  const handleDropdownToggleReg2 = () => {
    setShowDropdownReg2(!showDropdownReg2);
  };

  const handleOptionClick2 = (option) => {
    setShowDropdownReg2(false);
  };

  const handleLoginClick = () => {
    setLoginActive(true);
    document.getElementById('login-btn').style.background = 'var(--Gradient-Primary, linear-gradient(180deg, #D4110F 0%, #FF4D00 100%))';
    document.getElementById('login-btn').style.color = '#FFFFFF';
    document.getElementById('signup-btn').style.background = '#FFFFFF';
    document.getElementById('signup-btn').style.color = '#d4110f';
    setOpen(true);
  };

  const handleSignupClick = () => {
    setLoginActive(false);
    document.getElementById('login-btn').style.background = '#FFFFFF';
    document.getElementById('login-btn').style.color = '#d4110f';
    document.getElementById('signup-btn').style.background = 'var(--Gradient-Primary, linear-gradient(180deg, #D4110F 0%, #FF4D00 100%))';
    document.getElementById('signup-btn').style.borderRadius = '5px';
    document.getElementById('signup-btn').style.color = '#FFFFFF';
    setOpen2(true);
    setSignupMode('email');
  };

  const onCloseModal = () => {
    setOpen(false);
    setLoginActive(true);
  };

  const onCloseModal2 = () => {
    setOpen2(false);
    setLoginActive(false);
    setSignupMode('email');
  };

  const logoutUser = () => {
    localStorage.clear();
    navigate('/');
  };

  const handleProfileIconClick = () => {
    handleDropdownToggle();
    if (!isLoggedIn) {
      setOpen(true); // Open the login component
    }
  };

  if (loading) {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img className="logo-navbar" src={logo} alt="ForunPoint-Logo" />
          </a>
          <div class="loader nav-item dropdown prfile_dropdown"></div>
        </div>
      </nav>
    );
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid main-container">
        <a className='offers-link' href="#"><img src={offerImg} alt="Offer Image" className='offer-img' /><span className='offers-text'>Offers & Discounts</span></a>
        <div className="logo-container">
          <a className="navbar-brand" href="/">
            <img className="logo-navbar" src={logo} alt="ForunPoint-Logo" />
          </a>
        </div>
        {isLoggedIn && (
          <div className="nav-item dropdown prfile_dropdown">
            <div
              className={`nav-link dropdowntogglestyle text-center ${showDropdown2 ? 'selected' : ''}`}
              role="button"
              onClick={handleDropdownToggleReg2}
            >
              <img src={user?.picture} className="user-profile-img" alt="User Profile" />
              <span className="user-name">{user?.firstName?.substring(0, 8)}</span> <IoMdArrowDropdown />
            </div>
            <div className={`dropdown-menu ${showDropdownReg2 ? 'show' : ''}`}>
              <a className="dropdown-item" onClick={() => handleOptionClick2('restaurant')} href="/profile-page">
                <CgProfile className="orders-ico" /> Profile
              </a>
              <a className="dropdown-item" onClick={() => handleOptionClick2('driver')} href="/past-orders">
                <BsJournalBookmark className="orders-ico" /> Past Orders
              </a>
              <a className="dropdown-item" onClick={() => handleOptionClick2('driver')} href="/active-orders">
                <BsBoxSeam className="orders-ico" /> Active Orders
              </a>
              <a className="dropdown-item" onClick={() => handleOptionClick2('driver')} href="">
                <TbLogout2 className="order-ico" /> <span className="logout-txt" onClick={logoutUser}>Logout</span>
              </a>
            </div>
          </div>
        )}
        <div className="login-btns-pos">
          {!isLoggedIn && (
            <button
              className="navbar-toggler"
              type="button"
              onClick={handleDropdownToggle}
            >
              <img className="user_profile_icon" src={profileIco} alt="Profile Icon" onClick={handleProfileIconClick} />
            </button>
          )}
          {/* <div className="item-count-contain">
            <img className="user_cart_icon" src={cartitem} alt="Cart Icon" onClick={toggleCartPopup} />
            {isLoggedIn && cartItems?.length > 0 && (
              <div className="cart-item-count">
                {cartItems.length}
              </div>
            )}
          </div> */}
          <div className="item-count-contain">
            <img className="user_cart_icon" src={cartitem} alt="Cart Icon" onClick={toggleCartPopup} />
            {isLoggedIn && (cartItems?.length > 0 || giftItems?.length > 0) && (
              <div className="cart-item-count">
                {cartItems?.length > 0 && giftItems?.length > 0
                  ? cartItems.length + giftItems.length
                  : cartItems?.length || giftItems?.length}
              </div>
            )}
          </div>


          <div className={`cart-popup ${showCartPopup ? 'open' : ''}`}>
            <button className="close-popup" onClick={toggleCartPopup}>
              <AiOutlineClose />
            </button>
            <CartComponent itemsInCart={cartItems} giftInCart={giftItems} paymentBy={paymentBy} onCartError={onCartError} cartForm={cartForm} receiverAddress={receiverAddress} />
          </div>
        </div>
        {!isLoggedIn && (
          // <div className={`collapse navbar-collapse ${showDropdown ? 'show' : ''}`}>
          // <div className="navbar-nav ms-auto">
          <>
            <div className="btns">
              <button
                id="login-btn"
                className={`login-btn ${loginActive ? 'login-active' : ''}`}
                onMouseDown={handleLoginClick}
              >
                Sign In
              </button>
              <button
                className={`signup-btn ${!loginActive ? 'signup-active' : ''}`}
                id="signup-btn"
                onMouseDown={handleSignupClick}
              >
                Sign Up
              </button>
            </div>
            <div className="modal">
              <LoginComponent open={open} onCloseModal={onCloseModal} />
            </div>
            <div className="modal">
              <SignupComponent open={open2} onCloseModal={onCloseModal2} signupMode={signupMode} setSignupMode={setSignupMode} />
            </div>
          </>
          // </div>
          // </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
