// import React, { useEffect, useState } from 'react';
// import CryptoJS from 'crypto-js';
// import moment from 'moment';

// const JazzCashForm = () => {
//   const [formData, setFormData] = useState({
//     pp_Version: '1.1',
//     pp_TxnType: 'MPQR',
//     pp_Language: 'EN',
//     pp_MerchantID: '98790685',
//     pp_Password: '3399w2cv12',
//     pp_TxnRefNo: '',
//     pp_Amount: '',
//     pp_TxnCurrency: 'PKR',
//     pp_TxnDateTime: '',
//     pp_TxnExpiryDateTime: '',
//     pp_BillReference: '',
//     pp_Description: '',
//     pp_ReturnURL: 'https://api-pakistan.d-vour.com/api/v1/orders/jazzcash-payment',
//     pp_SecureHash: '',
//   });

//   useEffect(() => {
//     const amount = 1;
//     const billRef = Math.floor(Math.random() * 1000);
//     const description = Math.floor(Math.random() * 1000);
//     const transID = `T${Math.floor(Math.random() * 1000000000000)}`;

//     setFormData(prevState => ({
//       ...prevState,
//       pp_Amount: amount * 100, // Amount in paisas
//       pp_TxnRefNo: transID,
//       pp_TxnDateTime: moment().format('YYYYMMDDHHmmss'),
//       pp_TxnExpiryDateTime: moment().add(1, 'days').format('YYYYMMDDHHmmss'),
//       pp_BillReference: `billRef${billRef}`,
//       pp_Description: `Test description of product - ${description}`
//     }));
//   }, []);

//   const calculateHash = () => {
//     const IntegritySalt = 'dz13zv0yy1'; // Update with correct salt if needed
//     let hashString = `${IntegritySalt}&`;

//     Object.keys(formData).forEach(key => {
//       if (formData[key] !== '') {
//         hashString += `${formData[key]}&`;
//       }
//     });

//     hashString = hashString.slice(0, -1); // Remove trailing '&'
//     console.log('Hash String:', hashString); // Debugging line

//     const hash = CryptoJS.HmacSHA256(hashString, IntegritySalt).toString(CryptoJS.enc.Hex);
//     console.log('Calculated Hash:', hash); // Debugging line

//     setFormData(prevState => ({
//       ...prevState,
//       pp_SecureHash: hash
//     }));
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     calculateHash();

//     // Set the calculated hash in the form data
//     const form = event.target;
//     form.querySelector('input[name="pp_SecureHash"]').value = formData.pp_SecureHash;

//     // Debugging line to confirm the hash is set
//     console.log('Form Data:', formData);

//     // Submit the form manually
//     form.submit();
//   };

//   return (
//     <div className="container mt-5 bg-white">
//       <h4>JazzCash HTTP POST Sample (Page Redirection v1.1)</h4>
//       <form
//         name="jsform"
//         method="post"
//         action="https://payments.jazzcash.com.pk/CustomerPortal/transactionmanagement/merchantform/"
//         onSubmit={handleSubmit}
//       >
//         {/* Form fields */}
//         {Object.keys(formData).map((key, index) => (
//           <input
//             key={index}
//             type="hidden"
//             name={key}
//             value={formData[key]}
//           />
//         ))}
//         <div className="row">
//           <div className="col-md-6">
//             <button
//               type="submit"
//               className="btn btn-primary"
//             >
//               Submit
//             </button>
//           </div>
//         </div>
//         <textarea
//           className="w-50 mt-3"
//           id="sorted_string"
//           name="sorted_string"
//           cols="30"
//           rows="5"
//           value={`${Object.keys(formData).map(key => `${key}: ${formData[key]}`).join('\n')}`}
//           readOnly
//         ></textarea>
//       </form>
//     </div>
//   );
// };

// export default JazzCashForm;
// import React, { useState } from 'react';
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import { BsCurrencyBitcoin } from 'react-icons/bs';

// const PaymentForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [error, setError] = useState(null);
//   const [isProcessing, setIsProcessing] = useState(false);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setIsProcessing(true);

//     if (!stripe || !elements) {
//       return;
//     }

//     const {clientSecret }= await fetch('/create-payment-intent',{
//       method:'POST',
//       headers:{
//         'Content-Type': 'application/json',
//       },
//       body:JSON.stringify({
//         paymentMethodType:'Card',
//         currency:'eur'
//       }),
//     }).then(r=> r.json());
//     const cardElement = elements.getElement(CardElement);

//     const { error, paymentMethod } = await stripe.createPaymentMethod({
//       type: 'card',
//       card: cardElement,
//     });
//     console.log('card', cardElement)
//     console.log('stripe', stripe)


//     if (error) {
//       setError(error.message);
//       setIsProcessing(false);
//     } else {
//       console.log('PaymentMethod created:', paymentMethod);
//       // Further processing, e.g., sending paymentMethod.id to your server
//       setIsProcessing(false);
//     }
//   };

//   return (
//     <form id='payment-form' onSubmit={handleSubmit}>
//       <label htmlFor="card-element"></label>
//       <CardElement id='card-element' />
//       {error && <div>{error}</div>}
//       <button type="submit" disabled={!stripe || isProcessing}>
//         {isProcessing ? 'Processing...' : 'Pay'}
//       </button>
//     </form>
//   );
// };

// export default PaymentForm;


// import React, { useState } from 'react';
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import './stripe.css';

// const PaymentForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [error, setError] = useState(null);
//   const [isProcessing, setIsProcessing] = useState(false);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setIsProcessing(true);

//     if (!stripe || !elements) {
//       setError('Stripe.js has not loaded yet. Please try again later.');
//       setIsProcessing(false);
//       return;
//     }

//     let clientSecret;
//     try {
//       const response = await fetch('https://forunpoint-pk-dev-000da96aacf1.herokuapp.com/api/v1/payments/create-payment-intent', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           paymentMethodType: 'card',
//           currency: 'usd',
//           amount: 10 * 100,
//         }),
//       });

//       const result = await response.json();
//       console.log('result payment intent', result)
//       clientSecret = result.clientSecret;

//       if (!clientSecret) {
//         setError('Failed to create payment intent.');
//         setIsProcessing(false);
//         return;
//       }
//     } catch (error) {
//       setError('Failed to create payment intent. Please try again.');
//       setIsProcessing(false);
//       return;
//     }

//     const cardElement = elements.getElement(CardElement);

//     const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
//       type: 'card',
//       card: cardElement,
//     });

//     if (paymentMethodError) {
//       setError(paymentMethodError.message);
//       setIsProcessing(false);
//       return;
//     }

//     const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
//       payment_method: paymentMethod.id,
//     });

//     if (confirmError) {
//       setError(confirmError.message);
//       setIsProcessing(false);
//       return;
//     }

//     console.log('Payment successful:', paymentIntent);
//     setIsProcessing(false);
//   };

//   return (
//     <form id='payment-form' onSubmit={handleSubmit}>
//       <label htmlFor="card-element" className='strip-card'>Card Details</label>
//       {/* <CardElement id='card-element' /> */}
//       <div style={{ border: '1px solid #ced4da', padding: '10px', borderRadius: '4px' }}>
//         <CardElement
//           id="card-element"
//           options={{
//             style: {
//               base: {
//                 color: '#495057',
//                 fontFamily: '"Open Sans", sans-serif',
//                 fontSize: '16px',
//                 '::placeholder': {
//                   color: '#6c757d',
//                 },
//               },
//               invalid: {
//                 color: '#dc3545',
//                 iconColor: '#dc3545',
//               },
//             },
//           }}
//         />
//       </div>

//       {error && <div style={{ color: 'red' }}>{error}</div>}
//       <button
//         type="submit"
//         disabled={!stripe || isProcessing}
//         className="beautiful-button"
//       >
//         {isProcessing ? 'Processing...' : 'Pay'}
//       </button>

//     </form>
//   );
// };

// export default PaymentForm;


// import React, { useState } from 'react';
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import { getMe } from '../../shared/apis';
// import './stripe.css';

// const PaymentForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [error, setError] = useState(null);
//   const [isProcessing, setIsProcessing] = useState(false);
//   const [isSavingCard, setIsSavingCard] = useState(false);
//   const [userStripeID, setUserStripeID] = useState();

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setIsProcessing(true);
//     const userProfile = await getMe();
//     setUserStripeID(userProfile.data.user.stripeCustomerId)
//     console.log('userstripeID',userProfile.data.user.stripeCustomerId)

//     if (!stripe || !elements) {
//       setError('Stripe.js has not loaded yet. Please try again later.');
//       setIsProcessing(false);
//       return;
//     }

//     let clientSecret;
//     try {
//       const response = await fetch('https://forunpoint-pk-dev-000da96aacf1.herokuapp.com/api/v1/payments/create-payment-intent', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           paymentMethodType: 'card',
//           currency: 'usd',
//           amount: 10 * 100, // Replace with actual amount
//         }),
//       });

//       const result = await response.json();
//       clientSecret = result.clientSecret;

//       if (!clientSecret) {
//         setError('Failed to create payment intent.');
//         setIsProcessing(false);
//         return;
//       }
//     } catch (error) {
//       setError('Failed to create payment intent. Please try again.');
//       setIsProcessing(false);
//       return;
//     }

//     const cardElement = elements.getElement(CardElement);

//     const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
//       type: 'card',
//       card: cardElement,
//       billing_details: {
//         // Add billing details here if needed
//       },
//     });

//     if (paymentMethodError) {
//       setError(paymentMethodError.message);
//       setIsProcessing(false);
//       return;
//     }

//     if (isSavingCard) {
//       try {
//         const setupIntentResponse = await fetch('https://forunpoint-pk-dev-000da96aacf1.herokuapp.com/api/v1/payments/attach', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             customerId: userStripeID,
//           }),
//         });

//         const setupIntentResult = await setupIntentResponse.json();
//         const setupIntentClientSecret = setupIntentResult.clientSecret;

//         if (!setupIntentClientSecret) {
//           setError('Failed to create setup intent.');
//           setIsProcessing(false);
//           return;
//         }

//         const { error: setupError, setupIntent } = await stripe.confirmCardSetup(setupIntentClientSecret, {
//           payment_method: paymentMethod.id,
//         });

//         if (setupError) {
//           setError(setupError.message);
//           setIsProcessing(false);
//           return;
//         }

//         console.log('Card saved successfully:', setupIntent.payment_method);
//       } catch (error) {
//         setError('Failed to save the card. Please try again.');
//         setIsProcessing(false);
//         return;
//       }
//     }

//     const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
//       payment_method: paymentMethod.id,
//     });

//     if (confirmError) {
//       setError(confirmError.message);
//       setIsProcessing(false);
//       return;
//     }

//     console.log('Payment successful:', paymentIntent);
//     setIsProcessing(false);
//   };


//   return (
//     <form id='payment-form' onSubmit={handleSubmit}>
//       <label htmlFor="card-element" className='strip-card'>Card Details</label>
//       <div style={{ border: '1px solid #ced4da', padding: '10px', borderRadius: '4px' }}>
//         <CardElement
//           id="card-element"
//           options={{
//             style: {
//               base: {
//                 color: '#495057',
//                 fontFamily: '"Open Sans", sans-serif',
//                 fontSize: '16px',
//                 '::placeholder': {
//                   color: '#6c757d',
//                 },
//               },
//               invalid: {
//                 color: '#dc3545',
//                 iconColor: '#dc3545',
//               },
//             },
//           }}
//         />
//       </div>

//       {error && <div style={{ color: 'red' }}>{error}</div>}

//       <div>
//         <label>
//           <input
//             type="checkbox"
//             checked={isSavingCard}
//             onChange={() => setIsSavingCard(!isSavingCard)}
//           />
//           Save card for future payments
//         </label>
//       </div>

//       <button
//         type="submit"
//         disabled={!stripe || isProcessing}
//         className="beautiful-button"
//       >
//         {isProcessing ? 'Processing...' : isSavingCard ? 'Save Card & Pay' : 'Pay'}
//       </button>
//     </form>
//   );
// };

// export default PaymentForm;



import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';  // Import Axios
import { getMe, updateUser } from '../../shared/apis';
import './stripe.css';

const PaymentForm = ({ fetchData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSavingCard, setIsSavingCard] = useState(false);
  const [userStripeID, setUserStripeID] = useState();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [userId, setUserId] = useState();

  const init = async () => {
    const userProfile = await getMe();
    setUserId(userProfile.data.user._id)
    setUserStripeID(userProfile.data.user.stripeCustomerId);
    setPaymentMethods(userProfile.data.user.paymentMethods || []);
  }

  useEffect(() => {
    init();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (!stripe || !elements) {
      setError('Stripe.js has not loaded yet. Please try again later.');
      setIsProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        // Add billing details here if needed
      },
    });

    if (paymentMethodError) {
      setError(paymentMethodError.message);
      setIsProcessing(false);
      return;
    }

    if (isSavingCard) {
      try {
        // const setupIntentResponse = await axios.post('https://api-pakistan.d-vour.com/api/v1/payments/attach-payment-method', {
        const setupIntentResponse = await axios.post(`${process.env.REACT_APP_API_URL}api/v1/payments/attach-payment-method`, {
          customerId: userStripeID,
          paymentMethodId: paymentMethod.id,
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const setupIntentClientSecret = setupIntentResponse.data.clientSecret;

        if (!setupIntentClientSecret) {
          setError('Failed to create setup intent.');
          setIsProcessing(false);
          return;
        }

        const { error: setupError, setupIntent } = await stripe.confirmCardSetup(setupIntentClientSecret, {
          payment_method: paymentMethod.id,
        });

        if (setupError) {
          setError(setupError.message);
          setIsProcessing(false);
          return;
        }

        // const updatedPaymentMethods = [...paymentMethods, setupIntent.payment_method];
        // setPaymentMethods(updatedPaymentMethods);
        // console.log('paymentMethods', updatedPaymentMethods)
        // await updateUser({ paymentMethods: updatedPaymentMethods });
        const updatedPaymentMethods = [...paymentMethods, setupIntent.payment_method];
        setPaymentMethods(updatedPaymentMethods);

        await updateUser(userId, { paymentMethods: updatedPaymentMethods });
        // console.log('paymentMethods', updatedPaymentMethods)




        // console.log('Card saved successfully:', setupIntent.payment_method);
      } catch (error) {
        setError('Failed to save the card. Please try again.');
        setIsProcessing(false);
        return;
      }
    }

    // if (!isSavingCard) {
    //   try {

    //     const response = await axios.post('https://forunpoint-pk-dev-000da96aacf1.herokuapp.com/api/v1/payments/create-payment-intent', {
    //       paymentMethodType: 'card',
    //       currency: 'usd',
    //       amount: 100, // Replace with actual amount
    if (!isSavingCard) {
      try {
        const savedSubtotal = localStorage.getItem('mulSubtotal');
        await new Promise((resolve) => setTimeout(resolve, 2000));
        // Retrieve and multiply the amount from local storage
        // console.log(savedSubtotal)
        // if (savedSubtotal && !isNaN(savedSubtotal)) {
        //   multipliedAmount = parseFloat(savedSubtotal) * 100; // Multiply by 100 for Stripe (cents)
        // } else {
        //   setError('Invalid or missing subtotal in local storage.');
        //   setIsProcessing(false);
        //   return;
        // }

        // Send the multiplied amount to the payment intent
        // const response = await axios.post('https://api-pakistan.d-vour.com/api/v1/payments/create-payment-intent', {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}api/v1/payments/create-payment-intent`, {
          paymentMethodType: 'card',
          currency: 'usd',
          amount: savedSubtotal,
          customerId: userStripeID, // Optional: if you want to associate with a customer
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const clientSecret = response.data.clientSecret;

        if (!clientSecret) {
          setError('Failed to create payment intent.');
          setIsProcessing(false);
          return;
        }

        const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethod.id,
        });

        if (confirmError) {
          setError(confirmError.message);
          setIsProcessing(false);
          return;
        }
        // setSelectedPaymentMethod(paymentMethod.id);  // Set selected payment method
        localStorage.setItem('selectedPaymentMethod', paymentMethod.id);
        setError('Payment successful. Click Placeorder from cart ')
        // console.log('Payment successful:', paymentIntent);
      } catch (error) {
        setError('Failed to create payment intent. Please try again.');
        setIsProcessing(false);
        return;
      }
    }

    setIsProcessing(false);

    if (fetchData) {
      fetchData();
    }
  };

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <label htmlFor="card-element" className='strip-card'>Card Details</label>
      <div style={{ border: '1px solid #ced4da', padding: '10px', borderRadius: '4px' }}>
        <CardElement
          id="card-element"
          options={{
            style: {
              base: {
                color: '#495057',
                fontFamily: '"Open Sans", sans-serif',
                fontSize: '16px',
                '::placeholder': {
                  color: '#6c757d',
                },
              },
              invalid: {
                color: '#dc3545',
                iconColor: '#dc3545',
              },
            },
          }}
        />
      </div>

      {error && <div style={{ color: 'red' }}>{error}</div>}
      {/* 
      <div>
        <label>
          <input
            type="checkbox"
            checked={isSavingCard}
            onChange={() => setIsSavingCard(!isSavingCard)}
          />
          Save card for future payments
        </label>
      </div> */}
      <div>
        <label style={{ display: 'flex', alignItems: 'center' }}>
          <input
            disabled
            type="checkbox"
            checked={isSavingCard}
            onChange={() => setIsSavingCard(!isSavingCard)}
            className='checksave'
            style={{ margin: '8px' }} // Adds some space between the checkbox and the text
          />
          Save card for future payments
        </label>
      </div>

      <button
        type="submit"
        disabled={!stripe || isProcessing}
        className="beautiful-button"
      >
        {isProcessing ? 'Processing...' : isSavingCard ? 'Save Card' : 'Pay & Place Order'}
      </button>

    </form>
  );
};

export default PaymentForm;







// import React, { useState, useEffect } from 'react';
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import { getMe } from '../../shared/apis';
// import './stripe.css';

// const PaymentForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [error, setError] = useState(null);
//   const [isProcessing, setIsProcessing] = useState(false);
//   const [isSavingCard, setIsSavingCard] = useState(false);
//   const [userStripeID, setUserStripeID] = useState(null);

//   useEffect(() => {
//     const fetchUserStripeID = async () => {
//       try {
//         const userProfile = await getMe();
//         const stripeID = userProfile.data.user.stripeCustomerId;
//         setUserStripeID(stripeID);
//         console.log('userStripeID', stripeID);
//       } catch (error) {
//         setError('Failed to fetch user data. Please try again later.');
//       }
//     };

//     fetchUserStripeID();
//   }, []);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setIsProcessing(true);

//     if (!stripe || !elements) {
//       setError('Stripe.js has not loaded yet. Please try again later.');
//       setIsProcessing(false);
//       return;
//     }

//     const cardElement = elements.getElement(CardElement);

//     const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
//       type: 'card',
//       card: cardElement,
//       billing_details: {
//         // Add billing details here if needed
//       },
//     });

//     if (paymentMethodError) {
//       setError(paymentMethodError.message);
//       setIsProcessing(false);
//       return;
//     }

//     // Attach the PaymentMethod to the customer
//     try {
//       const attachPaymentMethodResponse = await fetch('https://forunpoint-pk-dev-000da96aacf1.herokuapp.com/api/v1/payments/attach', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           customerId: userStripeID,
//           paymentMethodId: paymentMethod.id,
//         }),
//       });

//       const attachResult = await attachPaymentMethodResponse.json();
//       console.log('resultssss', attachResult)
//       if (!attachResult.success) {
//         setError('Failed to attach the payment method to the customer.');
//         setIsProcessing(false);
//         return;
//       }

//       console.log('Payment method attached successfully:', attachResult);
//     } catch (error) {
//       setError('Failed to attach the payment method to the customer. Please try again.');
//       setIsProcessing(false);
//       return;
//     }

//     // Now proceed with the Payment Intent or Setup Intent
//     let clientSecret;
//     try {
//       const response = await fetch('https://forunpoint-pk-dev-000da96aacf1.herokuapp.com/api/v1/payments/create-payment-intent', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           paymentMethodType: 'card',
//           currency: 'usd',
//           amount: 10 * 100,
//         }),
//       });

//       const result = await response.json();
//       clientSecret = result.clientSecret;

//       if (!clientSecret) {
//         setError('Failed to create payment intent.');
//         setIsProcessing(false);
//         return;
//       }
//     } catch (error) {
//       setError('Failed to create payment intent. Please try again.');
//       setIsProcessing(false);
//       return;
//     }

//     if (isSavingCard) {
//       try {
//         const setupIntentResponse = await fetch('https://forunpoint-pk-dev-000da96aacf1.herokuapp.com/api/v1/payments/create-setup-intent', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             customerId: userStripeID,
//           }),
//         });

//         const setupIntentResult = await setupIntentResponse.json();
//         const setupIntentClientSecret = setupIntentResult.clientSecret;

//         if (!setupIntentClientSecret) {
//           setError('Failed to create setup intent.');
//           setIsProcessing(false);
//           return;
//         }

//         const { error: setupError, setupIntent } = await stripe.confirmCardSetup(setupIntentClientSecret, {
//           payment_method: paymentMethod.id,
//         });

//         if (setupError) {
//           setError(setupError.message);
//           setIsProcessing(false);
//           return;
//         }

//         console.log('Card saved successfully:', setupIntent.payment_method);
//       } catch (error) {
//         setError('Failed to save the card. Please try again.');
//         setIsProcessing(false);
//         return;
//       }
//     }

//     const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
//       payment_method: paymentMethod.id,
//     });

//     if (confirmError) {
//       setError(confirmError.message);
//       setIsProcessing(false);
//       return;
//     }

//     console.log('Payment successful:', paymentIntent);
//     setIsProcessing(false);
//   };

//   return (
//     <form id='payment-form' onSubmit={handleSubmit}>
//       <label htmlFor="card-element" className='strip-card'>Card Details</label>
//       <div style={{ border: '1px solid #ced4da', padding: '10px', borderRadius: '4px' }}>
//         <CardElement
//           id="card-element"
//           options={{
//             style: {
//               base: {
//                 color: '#495057',
//                 fontFamily: '"Open Sans", sans-serif',
//                 fontSize: '16px',
//                 '::placeholder': {
//                   color: '#6c757d',
//                 },
//               },
//               invalid: {
//                 color: '#dc3545',
//                 iconColor: '#dc3545',
//               },
//             },
//           }}
//         />
//       </div>

//       {error && <div style={{ color: 'red' }}>{error}</div>}

//       <div>
//         <label>
//           <input
//             type="checkbox"
//             checked={isSavingCard}
//             onChange={() => setIsSavingCard(!isSavingCard)}
//           />
//           Save card for future payments
//         </label>
//       </div>

//       <button
//         type="submit"
//         disabled={!stripe || isProcessing}
//         className="beautiful-button"
//       >
//         {isProcessing ? 'Processing...' : isSavingCard ? 'Save Card & Pay' : 'Pay'}
//       </button>
//     </form>
//   );
// };

// export default PaymentForm;
