import React, { useState, useEffect } from 'react';
import Modal from 'react-responsive-modal';
import { IoIosStar } from "react-icons/io";
import { LuMinusCircle, LuPlusCircle } from "react-icons/lu";
import { ConvertPKRtoUSD } from '../../../App';
import './gift-popup.css'

const GiftDetailsPopup = ({ isOpen, onClose, giftDetails, giftToCart }) => {
    const [quantity, setQuantity] = useState(1);
    const [total, setTotal] = useState(0);
    const [addontotal, setAddonTotal] = useState(0);

    useEffect(() => {
        getTotalPrice();
    }, [addontotal, quantity, setTotal, giftDetails]);

    const getTotalPrice = () => {
        let price = 0;
        price = (giftDetails?.price * quantity) + price;
        setTotal(price);
    };

    const increaseQuantity = () => {
        setQuantity(quantity + 1);
        getTotalPrice();
    };

    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
        getTotalPrice();
    };



    const renderStars = (rating) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            if (i < rating) {
                stars.push(<IoIosStar key={i} className='star-ico' />);
            } else {
                stars.push(<IoIosStar key={i} className='star-ico' id='black-ico' />);
            }
        }
        return stars;
    };


    if (!isOpen || !giftDetails) {
        return null;
    }

    const handleAddToCart = () => {
        let giftItems = {
            dish: giftDetails,
            quantity: quantity,
        }
        if (giftToCart && typeof giftToCart === 'function') {
            giftToCart({
                giftItems: giftItems,
                id: giftDetails._id,
                name: giftDetails.name,
                quantity: quantity,
                price: giftDetails.price,
                image: giftDetails.picture,
            });
            closeModel();
        }
    };


    const closeModel = () => {
        onClose();
        setTotal(0);
        setQuantity(1);
        setAddonTotal(0);
    }

    return (
        <div>
            <Modal className='details-modal' open={isOpen} onClose={closeModel} center>
                <div>
                    <div className='modal-header'>
                        <img src={giftDetails?.picture} className='dish-image' alt='dish-img' />
                    </div>
                    <div className="modal-body">
                        <h2 className='dish-name'>{giftDetails.name}</h2>
                        {ConvertPKRtoUSD(giftDetails?.price) && <p className='price-home'>{ConvertPKRtoUSD(giftDetails?.price)} </p>}

                    </div>
                    {giftDetails.description !== '' && <div className="modal-details gift-modal-details">
                        <p className="modal-description">
                            <b>Description: </b>
                            <p>{giftDetails?.description}</p>
                        </p>
                    </div>
                    }

                </div>

                <div className="bottom-bar">
                    {/* <div className="quantity-handler">
                        <span onClick={decreaseQuantity}><LuMinusCircle /></span>
                        <p>{quantity}</p>
                        <span onClick={increaseQuantity}><LuPlusCircle /></span>
                    </div> */}
                    <div>
                        <button className="add-to-cart-btn" onClick={handleAddToCart} >Add To Cart</button>
                    </div>
                    <div>
                        <button disabled className="view-cart-btn">View Cart</button>
                    </div>
                    <div>
                        <button className="price-btn">{ConvertPKRtoUSD(total?.toFixed(2))}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default GiftDetailsPopup
