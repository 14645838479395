// import React from 'react'
// import { ReactSearchAutocomplete } from 'react-search-autocomplete'
// import './Autocomplete.css';
// import { useState, useEffect } from 'react';
// import { getMe, placeDetail, updateUser, autoComplete } from '../../../shared/apis';
// import { v4 as uuid } from 'uuid';
// import { useNavigate } from 'react-router-dom'; 
// import Toast from 'react-bootstrap/Toast';


// function Autocomplete() {
//     const [User, setUser] = useState([]);
//     const [Adress, setAdress] = useState("Location...");
//     const [items, setitems] = useState([]);
//     const [uuid4, setuuid4] = useState('');
//     const [showToast, setShowToast] = useState(false);
//     const navigate = useNavigate();
//     const [scrollPosition, setScrollPosition] = useState(0);




//     const init = async () => {
//         let user = await getMe();
//         setUser(user.data.user);
//         const userAddress = JSON.parse(localStorage.getItem('UserAddress'));
//         if (user.data.user.streetAddress) {
//             setAdress(user.data.user.streetAddress);
//         } else if (userAddress) {
//             setAdress(truncateString(userAddress.streetAddress));
//         }
//         setuuid4(uuid())
//     };
//       const showToastFunction = (e) => {
//     if (!User || User === null || User._id === null) {
//       e.preventDefault(); // Prevent the default action of the anchor tag

//       showToast();
//        setShowToast(true);
//         setTimeout(() => {
//             setShowToast(false);
//         }, 3000);
//     } else {
//       // User is logged in, allow navigation
//       navigate('/restaurants');
//     }}

//     const truncateString = (input) => {
//         const regex = /[a-zA-Z]/g;
//         const matches = input.match(regex);
//         if (!matches || matches.length <= 45) {
//             return input;
//         }
//         return input.substring(0, 45) + '...';
//     };
//     const toastStyle = {
//         position: 'fixed',
//         top: `${scrollPosition}px`,
//         right: '10px',
//         zIndex: 1000,
//     };


//     useEffect(() => {
//         init();
//     }, []);

//     const handleOnSearch = async (string, results) => {
//         let test = [];
//         let result = await autoComplete(string, uuid4);
//         console.log('AutoComplete Called!');
//         for (let i = 0; i < result?.data?.places?.predictions.length; i++) {
//             test.push({
//                 id: i,
//                 name: result?.data?.places?.predictions[i]?.description,
//                 placeId: result?.data?.places?.predictions[i]?.place_id,
//             })
//         }
//         setitems(test);
//     }

//     const handleOnSelect = async (item) => {
//         let place = await placeDetail(item.placeId, uuid4)
//         console.log('PlaceDetails Called!');
//         let body = {
//             streetAddress: place.data.places.result.formatted_address,
//             location: {
//                 type: "Point",
//                 coordinates: [place.data.places.result.geometry.location.lng, place.data.places.result.geometry.location.lat]
//             },
//             address: {
//                 street: place.data.places.result.formatted_address,
//             }
//         }
//         localStorage.setItem('UserAddress', JSON.stringify(body))
//         if (User._id !== null) {
//             await updateUser(User._id, body);
//         }
//     }
//     const formatResult = (item) => {
//         return (
//             <>
//                 <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
//             </>
//         )
//     }

//     return (
//         <div>
//                {showToast &&
//                 <Toast style={toastStyle}>
//                     <Toast.Header>
//                         <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
//                         <strong className="me-auto">Error</strong>
//                     </Toast.Header>
//                     <Toast.Body>Sign In to continue.</Toast.Body>
//                 </Toast>
//             }
//             <div className='search-field' style={{ width: 512, position: 'sticky', zIndex: '100' }}>
//                 <img className="location-icon" src="./images/Vector.svg" alt="" />
//                 <ReactSearchAutocomplete
//                     styling={
//                         {
//                             borderRadius: "24px !important",
//                         }
//                     }
//                     items={items}
//                     onSearch={handleOnSearch}
//                     onSelect={handleOnSelect}
//                     formatResult={formatResult}
//                     placeholder={Adress}
//                     showIcon={false}
//                 />
//                 {/* <button class="input-group-text amber lighten-3" id="basic-text1"><a href="/restaurants" className="order-btn-txt">Order Now</a></button> */}
//                 <button className="input-group-text amber lighten-3" id="basic-text1" onClick={showToastFunction}>
//                     <a href="/restaurants" className="order-btn-txt">Order Now</a>
//                 </button>

//             </div>
//         </div>
//     )
// }

// export default Autocomplete;


// import React from 'react';
// import { ReactSearchAutocomplete } from 'react-search-autocomplete';
// import './Autocomplete.css';
// import { useState, useEffect } from 'react';
// import { getMe, placeDetail, updateUser, autoComplete } from '../../../shared/apis';
// import { v4 as uuid } from 'uuid';
// import { useNavigate } from 'react-router-dom';
// import Toast from 'react-bootstrap/Toast';

// function Autocomplete() {
//     const [User, setUser] = useState([]);
//     const [Adress, setAdress] = useState("Location...");
//     const [items, setitems] = useState([]);
//     const [uuid4, setuuid4] = useState('');
//     const [showToast, setShowToast] = useState(false);
//     const navigate = useNavigate();

//     const init = async () => {
//         let user = await getMe();
//         setUser(user.data.user);
//         const userAddress = JSON.parse(localStorage.getItem('UserAddress'));
//         if (user.data.user.streetAddress) {
//             setAdress(user.data.user.streetAddress);
//         } else if (userAddress) {
//             setAdress(truncateString(userAddress.streetAddress));
//         }
//         setuuid4(uuid());
//     };

//     const showToastFunction = (e) => {
//         if (!User || User === null || User._id === null) {
//             e.preventDefault(); // Prevent the default action of the anchor tag

//             setShowToast(true);
//             setTimeout(() => {
//                 setShowToast(false);
//             }, 3000);
//         } else {
//             // User is logged in, allow navigation
//             navigate('/restaurants');
//         }
//     };

//     const truncateString = (input) => {
//         const regex = /[a-zA-Z]/g;
//         const matches = input.match(regex);
//         if (!matches || matches.length <= 45) {
//             return input;
//         }
//         return input.substring(0, 45) + '...';
//     };

//     const toastStyleHome = {
//         position: 'fixed',
//         top: '10px',
//         right: '10px',
//         zIndex: 1000,
//     };

//     useEffect(() => {
//         init();
//     }, []);

//     const handleOnSearch = async (string, results) => {
//         let test = [];
//         let result = await autoComplete(string, uuid4);
//         console.log('AutoComplete Called!');
//         for (let i = 0; i < result?.data?.places?.predictions.length; i++) {
//             test.push({
//                 id: i,
//                 name: result?.data?.places?.predictions[i]?.description,
//                 placeId: result?.data?.places?.predictions[i]?.place_id,
//             });
//         }
//         setitems(test);
//     };

//     const handleOnSelect = async (item) => {
//         let place = await placeDetail(item.placeId, uuid4);
//         console.log('PlaceDetails Called!');
//         let body = {
//             streetAddress: place.data.places.result.formatted_address,
//             location: {
//                 type: "Point",
//                 coordinates: [place.data.places.result.geometry.location.lng, place.data.places.result.geometry.location.lat],
//             },
//             address: {
//                 street: place.data.places.result.formatted_address,
//             },
//         };
//         localStorage.setItem('UserAddress', JSON.stringify(body));
//         if (User._id !== null) {
//             await updateUser(User._id, body);
//         }
//     };

//     const formatResult = (item) => {
//         return (
//             <>
//                 <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
//             </>
//         );
//     };

//     return (
//         <div>
//             {showToast &&
//                 <Toast style={toastStyleHome}>
//                     <Toast.Header>
//                         <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
//                         <strong className="me-auto">Error</strong>
//                     </Toast.Header>
//                     <Toast.Body>Sign In to continue.</Toast.Body>
//                 </Toast>
//             }
//             <div className='search-field' style={{ width: 512, position: 'sticky', zIndex: '100' }}>
//                 <img className="location-icon" src="./images/Vector.svg" alt="" />
//                 <ReactSearchAutocomplete
//                     styling={{
//                         borderRadius: "24px !important",
//                     }}
//                     items={items}
//                     onSearch={handleOnSearch}
//                     onSelect={handleOnSelect}
//                     formatResult={formatResult}
//                     placeholder={Adress}
//                     showIcon={false}
//                 />
//                 <button className="input-group-text amber lighten-3" id="basic-text1" onClick={showToastFunction}>
//                     <a href="/restaurants" className="order-btn-txt">Order Now</a>
//                 </button>
//             </div>
//         </div>
//     );
// }

// export default Autocomplete;

import React, { useState, useEffect } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import './Autocomplete.css';
import { getMe, placeDetail, updateUser, autoComplete } from '../../../shared/apis';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';

function Autocomplete() {
    const [User, setUser] = useState([]);
    const [Adress, setAdress] = useState("Enter Your Delivery Address");
    const [items, setItems] = useState([]);
    const [uuid4, setUuid4] = useState('');
    const [showToast, setShowToast] = useState(false);
    const navigate = useNavigate();

    const init = async () => {
        let user = await getMe();
        setUser(user.data.user);
        const userAddress = JSON.parse(localStorage.getItem('UserAddress'));
        if (user.data.user.streetAddress) {
            setAdress(user.data.user.streetAddress);
        } else if (userAddress) {
            setAdress(truncateString(userAddress.streetAddress));
        }
        setUuid4(uuid());
    };

    const showToastFunction = (e) => {
        if (Adress === "Enter Your Delivery Address") {
            e.preventDefault(); // Prevent the default action of the anchor tag

            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        } else {
            navigate('/restaurants');
        }
    };

    const truncateString = (input) => {
        const regex = /[a-zA-Z]/g;
        const matches = input.match(regex);
        if (!matches || matches.length <= 45) {
            return input;
        }
        return input.substring(0, 45) + '...';
    };

    useEffect(() => {
        init();
    }, []);

    const handleOnSearch = async (string, results) => {
        let test = [];
        let result = await autoComplete(string, uuid4);
        // console.log('AutoComplete Called!');
        for (let i = 0; i < result?.data?.places?.predictions.length; i++) {
            test.push({
                id: i,
                name: result?.data?.places?.predictions[i]?.description,
                placeId: result?.data?.places?.predictions[i]?.place_id,
            });
        }
        setItems(test);
    };

    const handleOnSelect = async (item) => {
        let place = await placeDetail(item.placeId, uuid4);
        // console.log('PlaceDetails Called!');
        let body = {
            streetAddress: place.data.places.result.formatted_address,
            location: {
                type: "Point",
                coordinates: [place.data.places.result.geometry.location.lng, place.data.places.result.geometry.location.lat],
            },
            address: {
                street: place.data.places.result.formatted_address,
            },
        };
        localStorage.setItem('UserAddress', JSON.stringify(body));
        setAdress(body.streetAddress);
        if (User._id !== null) {
            await updateUser(User._id, body);
        }
    };

    const formatResult = (item) => {
        return (
            <>
                <span className='result-item-name'>{item.name}</span>
            </>
        );
    };

    return (
        <div>
            {showToast &&
            
                <div className="custom-toast">
                    <div className="custom-toast-header">
                        <strong className="custom-toast-title">Message</strong>
                        <button className="custom-toast-close">&times;</button>
                    </div>
                    <div className="custom-toast-body">
                        Please enter your address to continue.

                    </div>
                </div>

            }
            <div className='search-field' style={{ width: 512, position: 'sticky', zIndex: 100 }}>
                <img className="location-icon" src="./images/Vector.svg" alt="Location Icon" />
                <ReactSearchAutocomplete
                    styling={{
                        borderRadius: "5px !important",
                    }}
                    items={items}
                    onSearch={handleOnSearch}
                    onSelect={handleOnSelect}
                    formatResult={formatResult}
                    placeholder={Adress}
                    showIcon={false}
                />
                <button className="input-group-text amber lighten-3" id="basic-text1" onClick={showToastFunction}>
                    Find Here
                </button>
            </div>
        </div>
    );
}

export default Autocomplete;
