import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/footer";
import "./message.css";

const MessageCeo = () => {
  return (
    <div>
      <Navbar />

      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <div class="message-card">
              <div class="card-body">
                <img className="seoimg" src="./images/ceo.png" alt="CEO" />
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-8">
            <div class="message-card">
              <div class="card-body">
                <h2 className="message-card1">Message from CEO</h2>
                <br />
                <h5 className="message-subhead">Dear valued customers and partners,</h5>
                <p className="message-seo-contenet" >
                  I am excited to announce the launch of our new mobile online
                  food delivery app, ForunPoint. At ForunPoint, we are committed to
                  providing an unparalleled food ordering and delivery
                  experience for our customers, while also supporting the growth
                  and success of our restaurant and chef partners. Our app is
                  designed to be a one-stop-shop for all your food cravings,
                  whether you're in the mood for a fancy restaurant meal,
                  delicious meal from professional chef, or a cozy home-cooked
                  snack. With our unique platform, you can easily browse menus
                  from a variety of restaurants and chefs, customize your order,
                  and have it delivered straight to your door by utilizing our
                  Forunpoint delivery option.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-12">
            <div class="message2-card">
              <div class="card-body">
                <p className="message-seo-contenet">
                  But that's not all - we also offer a unique dashboard for our
                  restaurant and chef partners, where they can manage their
                  orders, view daily or weekly sales and profits, and analyze
                  customer feedback. This dashboard is designed to provide our
                  partners with the tools and insights they need to grow their
                  business and offer even better service to our customers.
                  <br /><br />
                  At ForunPoint, we are passionate about supporting our local
                  communities and promoting the rich diversity of cuisines and
                  culinary talents that exist within them. We are constantly
                  expanding our partnerships with restaurants and chefs, and we
                  welcome all interested parties to join us on this exciting
                  journey. <br /><br />Thank you for choosing ForunPoint for your food ordering
                  and delivery needs. We look forward to serving you and
                  building a thriving food community together.
                  <br />
                  <br />
                  Sincerely, <br /> <br />Syed Reaz Ashraf <br /> CEO & Founder ForunPoint
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MessageCeo;
