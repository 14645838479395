import React, { useState, useEffect } from 'react';
import './cart.css';
import cartimg from "../../../assets/images/cart.png"
import { useNavigate } from 'react-router-dom';
import { createOrder, getMe, createCheckout, createTrip, createGiftOrder, getCouponDetails } from '../../../shared/apis';
import { ConvertPKRtoUSD } from '../../../App';

const CartComponent = ({ showToast, pmId, itemsInCart, addToCart, giftToCart, giftInCart, paymentBy, cartForm, riderNote, onCartError }) => {
    const [subTotal, setSubTotal] = useState(0);
    const [subTotal2, setSubTotal2] = useState(0);
    const [bothSubtotal, setBothSubtotal] = useState(0);
    // const [coordinates] = useState([31.3728262, 74.1592163]);
    const [baseDeliveryFee] = useState(65);
    const [deliveryFee, setDeliveryFee] = useState(2);
    const [userLoc, setUserLoc] = useState({});
    const [platformFee, setPlatformFee] = useState(0);
    const [cartItems, setCartItems] = useState(itemsInCart);
    const [giftItems, setGiftItems] = useState(giftInCart);
    const [tax, setTax] = useState(0);
    const [tax2, setTax2] = useState(0);
    const [user, setUser] = useState(null);
    const [tipAmount, setTipAmount] = useState('');
    const [showOtherTipInput, setShowOtherTipInput] = useState(false);
    const [orderType, setOrderType] = useState("DELIVERY");
    const [isVisible, setIsVisible] = useState(true);
    const [showDeliveryFee, setshowDeliveryFee] = useState(false);
    const navigate = useNavigate();
    const [deliveryButtonStyle, setDeliveryButtonStyle] = useState({ backgroundColor: '#d4110f', color: '#F3F3F3' });
    const [pickupButtonStyle, setPickupButtonStyle] = useState({ backgroundColor: '#F3F3F3', color: '#d4110f' });
    let deliverycharges = 20;
    const [couponCodeInput, setCouponCodeInput] = useState('');
    const [couponAmount, setCouponAmount] = useState(null);
    const [couponCode, setCouponCode] = useState('');
    const [isTaxpayable, setIsTaxpayable] = useState();
    const storedCountry = localStorage.getItem('country');
    const userAddress = JSON.parse(localStorage.getItem('UserAddress'));
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [paymentByz, setPaymentByz] = useState('Card');
    const receiverAddress = localStorage.getItem('userDeliveryAddress');

    const stripeCheck = localStorage.getItem('selectedPaymentMethod');
    let isOrderDetailPage;
    if (window.location.href.includes("/order-details")) {
        isOrderDetailPage = true;
    }
    // const videoURL = videoBlob ? URL.createObjectURL(videoBlob) : null;

    // console.log('Video URL:', videoURL);

    const init = async () => {
        let user = await getMe();
        setUser(user.data.user);
    }

    useEffect(() => {
        init();

        const storedObjectString = localStorage.getItem('cartItems');
        if (storedObjectString) {
            const retrievedObject = JSON.parse(storedObjectString)
            setCartItems(retrievedObject);
            if (retrievedObject[0]?.orderType === 'PICKUP') {
                setshowDeliveryFee(false)
                setOrderType('PICKUP')
            } else {
                setshowDeliveryFee(true)
                setOrderType('DELIVERY')
            }
        }

        if (Array.isArray(cartItems) && cartItems.length > 0) {
            setIsTaxpayable(cartItems[0]?.isTaxPayable)
        }

        const storedObjectString2 = localStorage.getItem('giftItems');
        if (storedObjectString2) {
            const retrievedObject2 = JSON.parse(storedObjectString2)
            setGiftItems(retrievedObject2);
            if (retrievedObject2[0]?.orderType === 'PICKUP') {
                setshowDeliveryFee(false)
                setOrderType('PICKUP')
            } else {
                setshowDeliveryFee(true)
                setOrderType('DELIVERY')
            }
        }
        const currentURL = window.location.href;

        // Check if the URL contains a specific string
        if (currentURL.includes("order-details")) {
            // If the URL contains the specific string, hide the div
            setIsVisible(false);
        }

        // console.log(itemsInCart)
        // console.log(addToCart)
        userLocation();

    }, [setCartItems], [setGiftItems]);

    // useEffect(() => {
    //     // Fetch the coupon details when the component mounts
    //     const fetchCouponDetails = async () => {
    //         try {
    //             const response = await getCouponDetails();
    //             if (response?.data?.coupon?.couponCode) {
    //                 setCouponCode(response.data.coupon.couponCode);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching coupon details:', error);
    //         }
    //     };

    //     fetchCouponDetails();
    // }, []);
    const handleCouponInputChange = (e) => {
        const userInput = e.target.value;
        setCouponCodeInput(userInput);

        // Check if the input matches the coupon code from the API
        if (userInput === couponCode) {
            // If the codes match, the couponAmount is already set
            console.log("Coupon matched. Amount:", couponAmount);
        } else {
            setCouponAmount(null); // Reset if it doesn't match
        }
    };

    const userLocation = async () => {
        let me = await getMe();

        let latlng = {};
        if (me.data.user.location?.coordinates) {
            latlng.lat = me.data.user.location.coordinates[1];
            latlng.lng = me.data.user.location.coordinates[0];
        } else if (userAddress) {
            latlng.lat = userAddress.location.coordinates[1];
            latlng.lng = userAddress.location.coordinates[0];
        }
        setUserLoc(latlng);
    }

    setTimeout(() => {
        setCartItems(itemsInCart);
        setGiftItems(giftInCart)
    }, 10);

    const haversine = (lat1, lon1, lat2, lon2) => {
        lat1 = toRadians(lat1);
        lon1 = toRadians(lon1);
        lat2 = toRadians(lat2);
        lon2 = toRadians(lon2);

        let dlat = lat2 - lat1;
        let dlon = lon2 - lon1;

        let a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const R = 6371;
        let distance = R * c;
        return distance.toFixed(2);
    }

    const toRadians = (degrees) => {
        return degrees * (Math.PI / 180);
    }


    const calculateTotal = () => {
        setDeliveryFee(deliverycharges);
        let dishPrice = cartItems?.reduce((total, item) => total + item.quantity * item.cartItems?.dish.price, 0);
        let giftPrice = giftItems?.reduce((total, item) => total + item.quantity * item.giftItems?.dish.price, 0);
        setPlatformFee(9);
        if (isTaxpayable && isTaxpayable === false) {
            setTax(0);
        } else if (isTaxpayable && isTaxpayable === true) {
            setTax(Number((((dishPrice) / 100) * 16).toFixed(2)));
        }
        // setTax(Number((((dishPrice) / 100) * 16).toFixed(2)));
        setTax2(Number((((giftPrice) / 100) * 16).toFixed(2)));
        if (storedCountry === 'US') {
            // setTax(Number((((dishPrice) / 100) * 0).toFixed(2)));
            // setTax2(Number((((giftPrice) / 100) * 0).toFixed(2)));
            setDeliveryFee(0);
            setPlatformFee(2);
            setTax(0)
            setTax2(0)

        }
        else if (storedCountry === 'PK') {
            if (giftItems && giftItems.length > 0) {
                setDeliveryFee(200)
            }

        }
        let tip = parseFloat(tipAmount) || 0;
        let subtotalWithTip = (dishPrice + tax + deliveryFee + platformFee + tip);
        let subtotalWithTip2 = (giftPrice + tax2 + platformFee + tip + deliveryFee);
        setSubTotal(subtotalWithTip);
        setSubTotal2(subtotalWithTip2);
        if (cartItems?.length > 0 && giftItems?.length > 0) {
            // Add dishPrice and giftPrice and apply deliveryFee, platformFee, and tip once
            setBothSubtotal(dishPrice + giftPrice + tax + deliveryFee + platformFee + tip);
        } else if (cartItems?.length > 0) {
            setBothSubtotal(subtotalWithTip); // Only dish items present
        } else if (giftItems?.length > 0) {
            setBothSubtotal(subtotalWithTip2); // Only gift items present
        }


    };


    // const calculateTotal = () => {
    //     let dishPrice = cartItems?.reduce((total, item) => {
    //             if (item.price > 0) {
    //                 return total + item.quantity * item.price;
    //             } else if (item.cartItems && item.cartItems.size && item.cartItems.size.price) {
    //                 return total + item.quantity * item.cartItems.size.price;
    //             }
    //             return total;
    //         }, 0);

    //     setPlatformFee(Number(((dishPrice / 100) * 10).toFixed(2)));
    //     setTax(Number((((dishPrice + platformFee + deliveryFee) / 100) * 8.25).toFixed(2)));
    //     // setSubTotal(dishPrice + tax + deliveryFee + platformFee);
    //     let tip = parseFloat(tipAmount) || 0; // Convert tipAmount to float, default to 0 if not provided
    //     let subtotalWithTip = (dishPrice + tax + deliveryFee + platformFee + tip);
    //     setSubTotal(subtotalWithTip);
    // };


    // const increaseQuantity = (itemId) => {
    //     setCartItems(cartItems.filter(item => {
    //         if (item.id === itemId) {
    //             ++item.quantity;
    //             item.price = item.quantity * item.cartItems.dish.price;
    //         }
    //     }));

    // };

    const increaseQuantity = (itemId) => {
        setCartItems((prevCartItems) => {
            const updatedCartItems = prevCartItems.map(item => {
                if (item.id === itemId) {
                    const newQuantity = ++item.quantity;
                    item.price = newQuantity * item.cartItems.dish.price;

                    return {
                        ...item,
                        quantity: newQuantity,
                    };
                }
                return item;
            });

            // Update localStorage with the new cart array
            localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
            // console.log("Updated cartItems in localStorage:", JSON.parse(localStorage.getItem('cartItems')));

            return updatedCartItems;
        });
    };



    const increaseQuantity2 = (itemId) => {
        setGiftItems(giftItems.filter(item => {
            if (item.id === itemId) {
                ++item.quantity;
                item.price = item.quantity * item.giftItems.dish.price;
            }
        }));

    };

    const [, forceUpdate] = React.useReducer(() => ({}), {});

    const decreaseQuantity = (itemId) => {
        console.log(cartItems)
        cartItems.reduce((result, item) => {
            console.log(item, itemId);
            if (item.id === itemId) {
                let index = cartItems.indexOf(item);
                console.log(item)
                let updatedQuantity = --item.quantity;
                console.log("Quantity:", item.quantity)
                console.log(updatedQuantity)
                item.price = updatedQuantity * item.cartItems.dish.price;
                if (updatedQuantity == 0) {
                    console.log(index)
                    if (index > -1) {
                        cartItems.splice(index, 1);
                    }
                }
            } else {
                result.push(item);
            }
            return result;
        }, []);
        console.log(cartItems)
        itemsInCart = cartItems;
        console.log(itemsInCart)
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
        setCartItems(cartItems);
        forceUpdate();
    };

    const decreaseQuantity2 = (itemId) => {
        giftItems.reduce((result, item) => {
            if (item.id === itemId) {
                let updatedQuantity = --item.quantity;
                item.price = updatedQuantity * item.giftItems.dish.price;

                if (updatedQuantity === 0) {
                    const index = giftItems.indexOf(item);
                    if (index > -1) {
                        giftItems.splice(index, 1);
                    }
                } else {
                    result.push(item);
                }
            } else {
                result.push(item);
            }
            return result;
        }, []);
        console.log(giftItems)
        setGiftItems(giftItems);
        giftInCart = giftItems;
        localStorage.setItem('giftItems', JSON.stringify(giftItems));

        if (giftItems.length === 0) {
            setTimeout(() => {
                localStorage.removeItem('giftItems');
            }, 700);
        }
        forceUpdate();
    };

    // const decreaseQuantity2 = (itemId) => {
    //     setGiftItems(giftItems.filter(item => {
    //         if (item.id === itemId) {
    //             --item.quantity;
    //             (item.price) = item.quantity * (item.giftItems.dish.price);
    //         }
    //         if (item.quantity == 0) {
    //             const index = giftItems.indexOf(item);
    //             if (index > -1) {
    //                 setGiftItems(giftItems.splice(index, 1));
    //                 setTimeout(() => {
    //                     localStorage.removeItem('giftItems');
    //                 }, 700);
    //             }
    //         }
    //     }));
    // }

    useEffect(() => {
        calculateTotal();
    }, [subTotal, platformFee, deliveryFee, isTaxpayable, tax, cartItems, itemsInCart, addToCart, giftToCart, pmId, decreaseQuantity, increaseQuantity]);

    const handleCheckout = () => {
        // Check if there are cart items or gift items
        if (itemsInCart && itemsInCart.length > 0) {
            setshowDeliveryFee("false")
            cartItems[0].orderType = orderType;
            localStorage.setItem('cartItems', JSON.stringify(cartItems));
            // console.log(cartItems[0].isTaxPayable)

            if (!user || user === null || user._id === null) {
                showToast();
            } else {
                navigate('/order-details', { state: { convertedSubtotal } });
            }
        } else if (giftInCart && giftInCart.length > 0) {
            setshowDeliveryFee("false")
            giftItems[0].orderType = orderType;
            localStorage.setItem('giftItems', JSON.stringify(giftItems));

            if (!user || user === null || user._id === null) {
                showToast();
            } else {
                navigate('/order-details');
            }
        }
    };

    // const stringToObjectId = str => {
    //     const isValidHex = /^[0-9a-fA-F]{24}$/.test(str);
    //     if (isValidHex) {
    //         const objectId = {
    //             _bsontype: 'ObjectID',
    //             id: str,
    //             toString: function () {
    //                 return str;
    //             }
    //         };
    //         return objectId;
    //     } else {
    //         throw new Error('Invalid ObjectId');
    //     }
    // };

    const placeOrder = async () => {
        // console.log('address',receiverAddress)
        let tripBody = {
            service: '61cc7a627a9c3679847ea78a',
            pm: "Cod",
            fareAmount: 65,
            stripeToken: "000",
            stripeCustomerId: user?.stripeCustomerId,
            paymentMethod: "STRIPE",
            paymentToken: "000",
            origin: [cartItems[0]?.cartItems?.dish?.user?.location?.coordinates[1], cartItems[0]?.cartItems?.dish?.user?.location?.coordinates[0]],
            sourceAddress: cartItems[0]?.cartItems?.dish?.user?.address?.street,
            destinationAddress: receiverAddress,
            destination: [user?.location?.coordinates[1], user?.location?.coordinates[0]],
            specialNotes: "Good",
            vehicleType: 'FP_REGULAR_BIKE',
            isPetAllowed: false,
            isRequiredFemaleDriver: false,
            isFPTrip: false,
            paymentBy: paymentBy,
        };

        let trip = await createTrip(tripBody);

        let orderItems = [];
        itemsInCart.forEach(item => {
            let addons = [];
            item.cartItems.selectedAddons.forEach(addon => {
                addons.push({ addon: addon._id, quantity: 1 })
            })
            let items = {
                dish: item.id,
                quantity: item.quantity,
                addons: addons
            }
            orderItems.push(items);
        })

        let orderBody = {
            address: receiverAddress,
            user: user?._id,
            orderItems: orderItems,
            orderType: orderType,
            orderStatus: 'PENDING',
            chef: itemsInCart[0].cartItems.dish.user._id,
            tripId: trip._id,
            stickerId: '',
            paymentBy: paymentBy,
            orderFrom: storedCountry,
            notes: riderNote,
            // deliveryDistance

        }
        // console.log(orderBody)


        let order = await createOrder(orderBody);
        if (order.status.code === 200) {
            let checkout = {
                orderId: order?.data?.order?._id,
                email: user.email,
                paymentMethod: pmId
            }
            // let payment = await createCheckout(checkout);
            // if (payment.status.code === 200) {
            // console.log('Success', payment);
            localStorage.removeItem('cartItems');
            localStorage.removeItem('giftItems');
            navigate('/restaurants');
            // }
        }
    }

    const placeGiftOrder = async () => {
        const storedCountry = localStorage.getItem('country');

        let giftOrderItems = [];
        giftInCart?.forEach(item => {
            let items = {
                gift: item?.id,
                quantity: item.quantity,
            }
            giftOrderItems.push(items);
            // console.log(item)

        })
        // console.log(giftOrderItems)
        let tripBody = {
            service: '61cc7a627a9c3679847ea78a',
            pm: "Cod",
            fareAmount: 200,
            stripeToken: "000",
            stripeCustomerId: user?.stripeCustomerId,
            paymentMethod: "STRIPE",
            paymentToken: "000",
            origin: [31.5013845, 74.3569145],
            sourceAddress: '17k/1 Gurumanghat Road, Block K, Gulberg III, Lahore',
            destinationAddress: receiverAddress,
            destination: [user?.location?.coordinates[1], user?.location?.coordinates[0]],
            specialNotes: "Good",
            vehicleType: 'FP_REGULAR_BIKE',
            isPetAllowed: false,
            isRequiredFemaleDriver: false,
            isFPTrip: false,
            paymentBy: paymentBy
        };
        // console.log(tripBody)

        let trip = await createTrip(tripBody);
        let giftOrderBody = {
            address: receiverAddress,
            user: user?._id,
            orderItems: giftOrderItems,
            orderType: 'DELIVERY',
            orderStatus: 'PENDING',
            tripId: trip._id,
            stickerId: cartForm.stickerId,
            paymentBy: paymentBy,
            orderFrom: storedCountry,
            deliveryFee: 10,
            notes: `${riderNote}, ${cartForm.name}${cartForm.email}${cartForm.appoint}`,
            message: cartForm.message,
            receiverPhone: cartForm.phone,
            video: cartForm.video
        }

        // console.log(trip)
        let giftOrder = await createGiftOrder(giftOrderBody);
        if (giftOrder.status.code === 200) {
            let checkout = {
                giftOrderId: giftOrder?.data?.order?._id,
                email: user.email,
                paymentMethod: pmId
            }
            // let giftPayment = await createCheckout(checkout);
            // if (giftPayment.status.code === 200) {
            // console.log('Success', giftPayment);
            localStorage.removeItem('giftItems');
            navigate('/restaurants');
            // }
        }
    }

    const handlePlaceOrder = () => {
        let cartValid = false; // Example validation result

        if (!receiverAddress) {
            // If the cart is not valid, trigger an error in the parent
            onCartError('Please enter delivery address before proceeding');
        } else {
            // Proceed with the normal flow
            onCartError(''); // Clear the error if everything is fine
        }
        const isPaymentMethodSelected = localStorage.getItem('selectedPaymentMethod')
        const isPaymentMethodSelected2 = localStorage.getItem('selectedPaymentMethod2')
        const isPaymentMethodSelected3 = localStorage.getItem('selectedPaymentMethod3')

        if (itemsInCart && itemsInCart?.length > 0) {
            if (isPaymentMethodSelected || isPaymentMethodSelected2 || isPaymentMethodSelected3 || stripeCheck) {
                console.log('Placed Order')
                placeOrder();
                // console.log('here 1')
                setIsButtonClicked(true);
                localStorage.removeItem('selectedPaymentMethod')
                localStorage.removeItem('selectedPaymentMethod2')
                localStorage.removeItem('selectedPaymentMethod3')
            }
            else {
                alert("Please Do Your Payment First")
            }
        } else if (giftInCart || giftInCart?.length > 0) {
            if (isPaymentMethodSelected || isPaymentMethodSelected2 || isPaymentMethodSelected3 || stripeCheck) {
                // console.log('Placed Order Gift')
                placeGiftOrder();
                setIsButtonClicked(true);
                localStorage.removeItem('selectedPaymentMethod')
                localStorage.removeItem('selectedPaymentMethod2')
                localStorage.removeItem('selectedPaymentMethod3')
            }
            else {
                alert("Please Do Your Payment First")
            }
        }
    };

    const tipChange1 = () => {
        document.getElementById('tip-1').style.backgroundColor = '#d4110f';
        document.getElementById('tip-1').style.color = '#fff';
        document.getElementById('tip-2').style.backgroundColor = 'transparent';
        document.getElementById('tip-2').style.color = 'black';
        document.getElementById('tip-3').style.backgroundColor = 'transparent';
        document.getElementById('tip-3').style.color = 'black';
        document.getElementById('tip-4').style.backgroundColor = 'transparent';
        document.getElementById('tip-4').style.color = 'black';
        document.getElementById('tip-5').style.backgroundColor = 'transparent';
        document.getElementById('tip-5').style.color = 'black';
        setTipAmount('10');
        closeOtherTipClick();
    }

    const tipChange2 = () => {
        document.getElementById('tip-1').style.backgroundColor = 'transparent';
        document.getElementById('tip-1').style.color = 'black';
        document.getElementById('tip-2').style.backgroundColor = '#d4110f';
        document.getElementById('tip-2').style.color = '#fff';
        document.getElementById('tip-3').style.backgroundColor = 'transparent';
        document.getElementById('tip-3').style.color = 'black';
        document.getElementById('tip-4').style.backgroundColor = 'transparent';
        document.getElementById('tip-4').style.color = 'black';
        document.getElementById('tip-5').style.backgroundColor = 'transparent';
        document.getElementById('tip-5').style.color = 'black';
        setTipAmount('30');
        closeOtherTipClick();
    }

    const tipChange3 = () => {
        document.getElementById('tip-1').style.backgroundColor = 'transparent';
        document.getElementById('tip-1').style.color = 'black';
        document.getElementById('tip-2').style.backgroundColor = 'transparent';
        document.getElementById('tip-2').style.color = 'black';
        document.getElementById('tip-3').style.backgroundColor = '#d4110f';
        document.getElementById('tip-3').style.color = '#fff';
        document.getElementById('tip-4').style.backgroundColor = 'transparent';
        document.getElementById('tip-4').style.color = 'black';
        document.getElementById('tip-5').style.backgroundColor = 'transparent';
        document.getElementById('tip-5').style.color = 'black';
        setTipAmount('50');
        closeOtherTipClick();
    }

    const tipChange4 = () => {
        document.getElementById('tip-1').style.backgroundColor = 'transparent';
        document.getElementById('tip-1').style.color = 'black';
        document.getElementById('tip-2').style.backgroundColor = 'transparent';
        document.getElementById('tip-2').style.color = 'black';
        document.getElementById('tip-3').style.backgroundColor = 'transparent';
        document.getElementById('tip-3').style.color = 'black';
        document.getElementById('tip-4').style.backgroundColor = '#d4110f';
        document.getElementById('tip-4').style.color = '#fff';
        document.getElementById('tip-5').style.backgroundColor = 'transparent';
        document.getElementById('tip-5').style.color = 'black';
        setTipAmount('80');
        closeOtherTipClick();
    }

    const tipChange5 = () => {
        document.getElementById('tip-1').style.backgroundColor = 'transparent';
        document.getElementById('tip-1').style.color = 'black';
        document.getElementById('tip-2').style.backgroundColor = 'transparent';
        document.getElementById('tip-2').style.color = 'black';
        document.getElementById('tip-3').style.backgroundColor = 'transparent';
        document.getElementById('tip-3').style.color = 'black';
        document.getElementById('tip-4').style.backgroundColor = 'transparent';
        document.getElementById('tip-4').style.color = 'black';
        document.getElementById('tip-5').style.backgroundColor = '#d4110f';
        document.getElementById('tip-5').style.color = '#fff';
        setTipAmount('');
        handleOtherTipClick();
    }

    const handleTipChange = (e) => {
        setTipAmount(e.target.value);
    };

    const handleOtherTipClick = () => {
        setShowOtherTipInput(true);
    };

    const closeOtherTipClick = () => {
        setShowOtherTipInput(false);
    };

    const selectOrderType = (type) => {
        setOrderType(type);
        if (type === "DELIVERY") {
            setshowDeliveryFee(true); // Show delivery fee
            setDeliveryButtonStyle({ background: 'var(--Gradient-Primary, linear-gradient(180deg, #D4110F 0%, #FF4D00 100%))', color: '#F3F3F3' });
            setPickupButtonStyle({ background: '#FFFFFF', color: '#d4110f' });
        } else if (type === "PICKUP") {
            setshowDeliveryFee(false); // Hide delivery fee
            setDeliveryButtonStyle({ background: '#FFFFFF', color: '#d4110f' });
            setPickupButtonStyle({ background: 'var(--Gradient-Primary, linear-gradient(180deg, #D4110F 0%, #FF4D00 100%)) !important', color: '#F3F3F3' });
        }
    };
    const convertedSubtotal = ConvertPKRtoUSD(bothSubtotal.toFixed(2))
    if (convertedSubtotal) {
        // const mulSubtotal = parseFloat(convertedSubtotal.replace('$', ''))*100;
        const mulSubtotal = Math.round(parseFloat(convertedSubtotal.replace('$', '')) * 100);
        localStorage.setItem('mulSubtotal', mulSubtotal);
        // setConvertedSubtotal(convertedSubtotal);
        // const onlyNumber = convertedSubtotal.replace(/[^\d.]/g, '');
        // setConvertedSubtotal=(onlyNumber);
        // console.log(mulSubtotal)
    }
    // useEffect(() => {
    //     if (isOrderDetailPage && stripeCheck) {
    //         handlePlaceOrder();
    //     }
    // }, [isOrderDetailPage, stripeCheck]); // Dependencies, so the effect runs when these values change


    return (
        <>
            <div className='cart'>
                <h2 className='cart-title'>My Cart</h2>
                {/* {recordedVideo && (
                    <video src={recordedVideo} controls width="600" />
                )} */}
                {/* {videoURL && (
        <div>
          <h3>Recorded Video:</h3>
          <video controls src={videoURL} width="600" />
        </div>
      )} */}
                <hr className='cart-hr' />
                <div className="cart-items-container">
                    {(cartItems && cartItems.length > 0) || (giftItems && giftItems.length > 0) ? (
                        <>
                            {isVisible && (
                                <div className="order-options">
                                    <button
                                        className={`pickup-btn ${(giftItems && giftItems.length >= 0) || storedCountry === 'US' ? 'disabled-pickup-btn' : ''}`}
                                        style={pickupButtonStyle}
                                        onClick={() => selectOrderType("PICKUP")}
                                        disabled={giftItems && giftItems.length >= 0 || storedCountry === 'US'}
                                    >
                                        Pick Up
                                    </button>
                                    <button
                                        className="delivery-btn"
                                        style={deliveryButtonStyle}
                                        onClick={() => selectOrderType("DELIVERY")}

                                    >
                                        Delivery
                                    </button>
                                </div>
                            )}
                            <div className='main-cartitems-sec'>
                                {cartItems && cartItems.length > 0 && cartItems.map((item) => (
                                    <div key={item.id} className='cart-items'>
                                        <img src={item.image} className='item-pic' />
                                        <span className='item-name'>{item.name.substring(0, 20)}</span>
                                        <br />
                                        <div className="quantity-btns">
                                            <button className='decrease-btn' onClick={() => decreaseQuantity(item.id, item.size)}>-</button>
                                            <span>{item.quantity}</span>
                                            <button className='increase-btn' onClick={() => increaseQuantity(item.id, item.size)}>+</button>
                                        </div>
                                        &ensp;
                                        {(ConvertPKRtoUSD(item.price) != null && (
                                            <span className='item-price'>
                                                {(ConvertPKRtoUSD(item.price))}
                                            </span>
                                        )) || (ConvertPKRtoUSD(item?.cartItems?.size?.price) != null && (
                                            <span className='item-price'>
                                                {(ConvertPKRtoUSD(item.cartItems.size.price))}
                                            </span>
                                        ))}
                                        {/* <hr /> */}
                                    </div>
                                ))}

                                {giftItems && giftItems.length > 0 && giftItems.map((item) => (
                                    <div key={item.id} className='cart-items'>
                                        <img src={item.image} className='item-pic gift-pic' />
                                        <span className='item-name'>{item.name.substring(0, 20)}</span>
                                        <br />
                                        <div className="quantity-btns">
                                            <button className='decrease-btn' onClick={() => decreaseQuantity2(item.id)}>-</button>
                                            <span>{item.quantity}</span>
                                            <button className='increase-btn' onClick={() => increaseQuantity2(item.id)}>+</button>
                                        </div>
                                        &ensp;
                                        {(ConvertPKRtoUSD(item.price) != null && (
                                            <span className='item-price'>
                                                {(ConvertPKRtoUSD(item.price))}
                                            </span>
                                        )) || (ConvertPKRtoUSD(item?.giftItems?.size?.price) != null && (
                                            <span className='item-price'>
                                                {(ConvertPKRtoUSD(item.giftItems.size.price))}
                                            </span>
                                        ))}
                                    </div>
                                ))}

                            </div>
                        </>

                    ) : (
                        <div className="empty-cart">
                            <br />
                            <img src={cartimg} className="cart-icon" />
                            <p className="cart-desc">Oops! Your Cart is a Little Hungry 🥪</p>
                            <p className="cart-subdesc" >We see you haven’t added any items yet. Find something tasty from our wide selection and fill up that cart! </p>
                        </div>

                    )}


                </div>
                {((cartItems && cartItems.length > 0) || (giftItems && giftItems.length > 0)) && (
                    <div>
                        <div className="prices-content">
                            <div className="order-summary">
                                <h2 className='summary-title'>Order Summary</h2>
                                {/* <p className="order-id-heading">Order Id <span className='order-id'>ASDW11268</span></p> */}
                                <div className="coupon-apply-sec">
                                    <input type="text" value={couponCodeInput} onChange={handleCouponInputChange} placeholder='Coupon Code' className="coupon-field" disabled />
                                    <button className='coupon-apply-btn' disabled>Apply</button>
                                    {couponAmount !== null && (
                                        <div>
                                            <p>Coupon Applied! Amount: {couponAmount}</p>
                                        </div>
                                    )}
                                </div>
                                <div className='tip-section'>
                                    <h3 className='tip-heading'>Give Tip</h3>
                                    <div className="tip-contain">
                                        <button className='tip-button' id='tip-1' onClick={() => tipChange1()}>{ConvertPKRtoUSD(10)}</button>
                                        <button className='tip-button' id='tip-2' onClick={() => tipChange2()}>{ConvertPKRtoUSD(30)}</button>
                                        <button className='tip-button' id='tip-3' onClick={() => tipChange3()}>{ConvertPKRtoUSD(50)}</button>
                                        <button className='tip-button' id='tip-4' onClick={() => tipChange4()}>{ConvertPKRtoUSD(80)}</button>
                                        <button className='tip-button' id='tip-5' onClick={() => tipChange5()}>Other</button>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            {showOtherTipInput && (
                                <div>
                                    <input
                                        type="number"
                                        className="other-tip-input"
                                        placeholder="Enter tip amount"
                                        value={tipAmount}
                                        onChange={handleTipChange}
                                    />
                                </div>
                            )}
                            <div className="price-sec">
                                <div className='subtotal-section'>
                                    <h3 className='subtotal-title'>Subtotal</h3>
                                    <p className='subtotal-amount'>
                                        {/* {(cartItems && cartItems.length > 0) ? ConvertPKRtoUSD(subTotal.toFixed(2)) : ConvertPKRtoUSD(subTotal2.toFixed(2))} */}
                                        {convertedSubtotal}

                                    </p>
                                </div>

                                <div className='delivery-section'>
                                    <h3 className='delivery-title'>Delivery Fee</h3>
                                    <p className='delivery-amount'>
                                        {
                                            (() => {
                                                if (!showDeliveryFee) {
                                                    return ConvertPKRtoUSD(deliverycharges = 0);
                                                }
                                                else if (giftItems && giftItems.length > 0) {
                                                    return ConvertPKRtoUSD(deliverycharges = deliveryFee);
                                                }
                                                else if (storedCountry === 'US' && cartItems && cartItems.length > 0) {
                                                    return ConvertPKRtoUSD(deliverycharges = deliveryFee);
                                                }

                                                else {
                                                    return ConvertPKRtoUSD(deliverycharges = haversine(
                                                        userLoc.lat,
                                                        userLoc.lng,
                                                        cartItems[0].cartItems.dish.user.location.coordinates[1],
                                                        cartItems[0].cartItems.dish.user.location.coordinates[0]
                                                    ) * 10 + (baseDeliveryFee));
                                                }
                                            })()
                                        }
                                    </p>
                                </div>


                                <div className='platform-section'>
                                    <h3 className='platform-title'>Platform Fee</h3>
                                    <p className='platform-amount'>
                                        {ConvertPKRtoUSD(platformFee.toFixed(0))}
                                    </p>
                                </div>
                                <div className='tax-section'>
                                    <h3 className='tax-title'>Tax</h3>
                                    <p className='tax-amount'>
                                        {(cartItems && cartItems.length > 0) ? ConvertPKRtoUSD(tax.toFixed(2)) : ConvertPKRtoUSD(tax2.toFixed(2))}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                {!pmId && !isOrderDetailPage && <button className={`checkout-btn ${((!itemsInCart || itemsInCart.length === 0) && (!giftInCart || giftInCart.length === 0)) ? 'disabled-checkout-btn' : ''}`}
                    onClick={handleCheckout}
                    disabled={(!itemsInCart || itemsInCart.length === 0) && (!giftInCart || giftInCart.length === 0)}
                >
                    Checkout
                </button>}
                {paymentByz && isOrderDetailPage && <button className={`checkout-btn`}
                    onClick={handlePlaceOrder} disabled={isButtonClicked}
                    style={{
                        backgroundColor: isButtonClicked ? 'grey' : '',
                        cursor: isButtonClicked ? 'not-allowed' : '',
                        opacity: isButtonClicked ? '0.6' : '1'
                    }}
                >
                    Place Order
                </button>}
                {/* <OrderDetails amount={convertedSubtotal}/> */}
            </div >
        </>
    );
};

export default CartComponent;